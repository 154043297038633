<template>
  <div>
    <h3 class="chart-title">
      {{ propChartData.title }}
    </h3>
    <div class="chart-holder">
      <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import ChartPropsWatcher from "@/mixins/ChartPropsWatcher";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    propChartData: {
      type: Object,
      default: () => {},
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [ChartPropsWatcher],
  data() {
    return {
      values: [],
      iconsLabel: [
        require("@/assets/images/charts/chart_sensitivity/sensitivity-icon-1.svg"),
        require("@/assets/images/charts/chart_sensitivity/sensitivity-icon-2.svg"),
        require("@/assets/images/charts/chart_sensitivity/sensitivity-icon-3.svg"),
        require("@/assets/images/charts/chart_sensitivity/sensitivity-icon-4.svg"),
      ],
      plugins: [
        {
          afterDraw: (chart) => {
            let ctx = chart.ctx;
            let yAxis = chart.scales.y;

            yAxis.ticks.forEach((value, index) => {
              var y = yAxis.getPixelForTick(index);
              var image = new Image();
              image.src = this.iconsLabel[index];
              ctx.drawImage(image, 13, y - 25);
            });
          },
        },
      ],
      chartOptions: {
        layout: {
          padding: {
            top: 55,
            left: 40,
            right: 20,
            bottom: -10,
          },
        },
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              font: {
                size: 20,
              },
            },
            grid: {
              display: false,
            },
            ticks: {
              beginAtZero: false,
              source: "data",
              autoSkip: true,
              maxTicksLimit: 7,
            },
          },
          y: {
            title: {
              display: true,
            },
            ticks: {
              display: false,
              maxTicksLimit: 4,
            },
            grid: {
              color: "#D8D8D8",
              drawBorder: false,
            },
            suggestedMin: 0,
            suggestedMax: 3,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.convertedLabels,
        datasets: [
          {
            label: false,
            pointStyle: false,
            pointRadius: 3,
            data: this.values,
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 650);

              gradient.addColorStop(0.4, "#C6B3A3");
              gradient.addColorStop(0.3, "#E2D1C3");
              gradient.addColorStop(0.2, "#F5EEE8");
              gradient.addColorStop(0.1, "#E2D1C3");

              return gradient;
            },
            borderColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 650);

              gradient.addColorStop(0.4, "#C6B3A3");
              gradient.addColorStop(0.3, "#E2D1C3");
              gradient.addColorStop(0.2, "#F5EEE8");
              gradient.addColorStop(0.1, "#E2D1C3");

              return gradient;
            },
          },
        ],
      };
    },
  },
};
</script>
