import moment from "moment";


export default {
	watch: {
		propChartData() {
			try {
				this.convertedLabels = this.propChartData.dates.map((el) => {
					const day = moment(el).format("D");
					return day >= 10 ? day : "0" + day;
				});

				this.values = this.propChartData.values.map((el) => {
					if (el !== -1) {
						return el;
					} else if (el == -1) {
						return null;
					}
				});
			} catch {
				return null;
			}
		},
	}
}