<template>
  <div class="all-chart">
    <div class="chart-holder charts-holder">
      <canvas id="myChart" height="300"></canvas>
      <span v-if="convertedLabels.length <= 0" class="no-data">
        {{ $t("common.no_data") }}
      </span>
    </div>
    <div class="legend-container" id="legend-container"></div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import moment from "moment";

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");

      // li.addEventListener("click", () => {
      //   var index = item.datasetIndex;

      //   var alreadyHidden =
      //     chart.getDatasetMeta(index).hidden === null
      //       ? false
      //       : chart.getDatasetMeta(index).hidden;

      //   console.log(chart.getDatasetMeta(index));

      //   chart.data.datasets.forEach(function (e, i) {
      //     var meta = chart.getDatasetMeta(i);

      //     if (i !== index) {
      //       if (!alreadyHidden) {
      //         meta.hidden = meta.hidden === null ? !meta.hidden : null;
      //       } else if (meta.hidden === null) {
      //         meta.hidden = true;
      //       }
      //     } else if (i === index) {
      //       meta.hidden = null;
      //     }
      //   });

      //   chart.update();
      // });

      li.onclick = () => {
        //console.log(item, index);
        //console.log(event.target);

        // chart.setDatasetVisibility(item.datasetIndex, false);

        const isHidden = !chart.isDatasetVisible(item.datasetIndex);
        chart.setDatasetVisibility(item.datasetIndex, isHidden);

        chart.update();
      };

      li.classList.add(item.hidden ? "deactive" : "li");

      // Text
      const textContainer = document.createElement("p");

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.display = "inline-block";

      if (typeof item.fillStyle == "string") {
        boxSpan.style.background = item.fillStyle;
      } else {
        boxSpan.style.background =
          "linear-gradient(270deg, #F36464 -73.61%, #FB976C -42.27%, #F8B147 -9.28%, #96DD94 22.7%, #5FAEF8 54.19%, #896CFE 86.17%, #615EFE 118.21%)";
      }

      li.appendChild(textContainer);
      li.appendChild(boxSpan);
      ul.appendChild(li);
    });
  },
};

export default {
  props: {
    propData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ctx: null,
      chart: null,
      convertedLabels: [],
      colors: [
        {
          id: "aggression",
          color: "#F36464",
        },
        {
          id: "anxiety",
          color: "#E0BD5B",
        },
        {
          id: "appetite",
          color: "#FFD600",
        },
        {
          id: "awake",
          color: "#62ABF9",
        },
        {
          id: "deep_sleep",
          color: "#BBD6AD",
        },
        {
          id: "libido",
          color: "#FF9B06",
        },
        {
          id: "meds",
          color: "#D4C5B8",
        },
        {
          id: "menstruation",
          color: "#CC2828",
        },
        {
          id: "nightmares",
          color: "#5C5FA9",
        },
        {
          id: "pav",
          color: "#387916",
        },
        {
          id: "pleasure",
          color: "#E851AB",
        },
        {
          id: "sensitivity",
          color: "#D49CFF",
        },
        {
          id: "sleep_time",
          color: "#47FFF4",
        },
        {
          id: "sleep_quality",
          color: "#96DD94",
        },
        {
          id: "mood",
          color: "#1E1E1E",
        },
      ],
    };
  },
  mounted() {
    this.ctx = document.getElementById("myChart").getContext("2d");

    this.chart = new Chart(this.ctx, {
      type: "line",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        plugins: {
          htmlLegend: {
            containerID: "legend-container",
          },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 20,
            left: 20,
            right: 25,
            bottom: -20,
          },
        },
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              font: {
                size: 20,
              },
            },
            grid: {
              display: false,
            },
            ticks: {
              beginAtZero: false,
              source: "data",
              autoSkip: true,
              maxTicksLimit: 7,
            },
          },
          y: {
            title: {
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: 100,
              stepSize: 10,
            },
            grid: {
              drawBorder: false,
              color: function () {
                return "#D8D8D8";
              },
            },
            suggestedMin: 0,
            suggestedMax: 100,
          },
        },
      },
      plugins: [htmlLegendPlugin],
    });
  },

  methods: {
    getPercent(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
    returnNewPercentValue(title, value) {
      if (title == "mood") {
        if (value > 6) {
          return this.getPercent(value, 7);
        } else if (value <= 1) {
          return this.getPercent(value, 100);
        } else {
          return this.getPercent(value, 7);
        }
      } else if (title == "sleep_time") {
        return this.getPercent(value, 20);
      } else if (
        title == "anxiety" ||
        title == "aggression" ||
        title == "libido" ||
        title == "sensitivity" ||
        title == "appetite" ||
        title == "pleasure"
      ) {
        return this.getPercent(value, 3);
      } else if (title == "sleep_quality") {
        return this.getPercent(value, 10);
      } else if (
        title == "menstruation" ||
        title == "meds" ||
        title == "pav" ||
        title == "awake" ||
        title == "nightmares" ||
        title == "deep_sleep"
      ) {
        return this.getPercent(value, 1);
      } else {
        return value;
      }
    },
    getColorById(array, id) {
      let color = "";

      array.forEach((el) => {
        if (id == el.id) {
          color = el.color;
        }
      });

      return color;
    },
    moodGradient() {
      const canvas = this.ctx;
      const gradient = canvas.createLinearGradient(0, 0, 0, 450);

      gradient.addColorStop(0.0, "#f36464");
      gradient.addColorStop(0.1, "#fb976c");
      gradient.addColorStop(0.2, "#f8b147");
      gradient.addColorStop(0.3, "#96dd94");
      gradient.addColorStop(0.4, "#5faef8");
      gradient.addColorStop(0.5, "#896cfe");
      gradient.addColorStop(0.6, "#615efe");

      return gradient;
    },
  },
  watch: {
    propData() {
      this.convertedLabels = this.propData.data.date.map((el) => {
        const day = moment(el).format("D");
        return day >= 10 ? day : "0" + day;
      });

      this.chart.data.labels = this.convertedLabels;

      this.values = [];

      for (let el in this.propData.data) {
        if (el !== "date") {
          const datasets = this.propData.data[el].map((item) => {
            if (item == -1) {
              return null;
            } else {
              return this.returnNewPercentValue(el, item);
            }
          });

          const color = this.getColorById(this.colors, el);

          this.values.push({
            label: this.$t(`charts.${el}`),
            data: datasets,
            backgroundColor: el == "mood" ? this.moodGradient() : color,
            borderColor: el == "mood" ? this.moodGradient() : color,
          });
        }
      }

      this.chart.data.datasets = this.values;

      this.chart.update();
    },
  },
};
</script>

<style lang="scss" scoped>
.all-chart {
  .no-data {
    height: 100%;
  }
}
</style>
