<template>
  <div class="default-page chart-page">
    <div class="container">
      <Calendar
        class="chart-calendar"
        @updateDate="updateDate"
        :attributesCalendar="attributesCalendar"
        :сategories="selectTabId"
      />
      <!-- {{ monthChartData }} -->
      <AllInOneChart
        v-if="selectTabId == 2"
        :prop-data="{
          data: monthChartData,
          dates: monthChartData.date,
        }"
      />
      <div v-else>
        <div
          v-for="chart in filteredSelectedCharts"
          :key="chart.id"
          class="charts-holder"
        >
          <div v-if="chart.isShow === true">
            <component
              v-bind:is="chart.componentName"
              :height="chart.height"
              :prop-chart-data="{
                values: monthChartData[chart.id],
                dates: monthChartData.date,
                title: chart.title,
              }"
            ></component>
            <span v-if="monthChartData.date.length <= 0" class="no-data">
              {{ $t("common.no_data") }}
            </span>
          </div>
        </div>
      </div>

      <div class="footer-btns">
        <van-button
          type="primary"
          class="button-white"
          @click="isShowChartsModal = true"
        >
          {{ $t("chart.btn_1") }}
        </van-button>
        <van-button
          type="primary"
          class="button-white"
          @click="routeWithSameQuery('Calendar')"
        >
          {{ $t("common.view_calendar") }}
        </van-button>
        <van-button
          type="primary"
          class="button-black"
          @click="closeTelegramWindow"
          >{{ $t("calendar.button2") }}</van-button
        >
      </div>

      <van-dialog
        v-model="isShowChartsModal"
        :show-confirm-button="false"
        class="mood-modal"
        :overlay="true"
        closeable
        close-icon="cross"
        close-icon-position="top-left"
        :close-on-click-overlay="true"
        @click-overlay="updateDate(selectedDate)"
      >
        <ScrollBar
          :scroll-settings="{
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false,
          }"
          height="310"
        >
          <ul class="checkboxs-list">
            <li
              v-for="(item, index) in filteredSelectedCharts"
              :key="index"
              class="list-item"
            >
              <van-checkbox
                v-model="item.isShow"
                checked-color="#000000"
                icon-size="16px"
                :class="item.isShow === true ? 'checked' : null"
                >{{ item.title }}</van-checkbox
              >
            </li>
          </ul>
        </ScrollBar>
        <h3 class="tabs-title">выводить графики:</h3>
        <Tabs
          :array="tabsArray"
          :select-id-in="selectTabId"
          v-model="selectTabId"
        />
        <van-button
          type="primary"
          class="button-black"
          @click="(isShowChartsModal = false), updateDate(selectedDate)"
          >{{ $t("buttons.complete") }}</van-button
        >
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/Tabs";
import MoodChart from "@/views/Chart/components/MoodChart";
import SleepQuality from "@/views/Chart/components/SleepQuality";
import SleepChart from "@/views/Chart/components/SleepChart";
import MedsChart from "@/views/Chart/components/MedsChart";
import NightmaresChart from "@/views/Chart/components/NightmaresChart";
import DeepSleepChart from "@/views/Chart/components/DeepSleepChart";
import SensitivityChart from "@/views/Chart/components/SensitivityChart";
import AnxietyChart from "@/views/Chart/components/AnxietyChart";
import Calendar from "@/components/Calendar";
import ApiCalendar from "@/api/calendar";
import selectedCharts from "@/mixins/selectedCharts";
import AllInOneChart from "@/views/Chart/components/AllInOneChart";
import moment from "moment";
import ScrollBar from "@/components/ScrollBar";
import globalFunctions from "@/mixins/globalFunctions";

export default {
  components: {
    Tabs,
    MoodChart,
    Calendar,
    SleepQuality,
    SleepChart,
    MedsChart,
    NightmaresChart,
    DeepSleepChart,
    SensitivityChart,
    ScrollBar,
    AnxietyChart,
    AllInOneChart,
  },
  mixins: [selectedCharts, globalFunctions],
  data() {
    return {
      filteredSelectedCharts: [],
      tabsArray: [
        {
          id: 1,
          title: this.$t("chart.tab_1"),
        },
        {
          id: 2,
          title: this.$t("chart.tab_2"),
        },
      ],
      currentDate: {
        year: null,
        month: null,
      },
      selectedDate: {
        year: null,
        month: null,
      },
      selectTabId: 1,
      attributesCalendar: null,
      monthChartData: {
        date: [],
        // mood: [],
        // pleasure: [],
        // sleep_time: [],
        // meds: [],
        // nightmares: [],
        // deep_sleep: [],
        // sensitivity: [],
        // anxiety: [],
        // pav: [],
        // awake: [],
        // aggression: [],
        // libido: [],
        // appetite: [],
        // menstruation: [],
        // sleep_quality: [],
      },
      isShowChartsModal: false,
    };
  },
  methods: {
    convertDateToString(date) {
      return `${date.year}-${
        date.month >= 10 ? date.month : "0" + date.month
      }-01`;
    },
    updateDate(date) {
      if (date.year && date.month) {
        let dateParms = this.convertDateToString(date);

        this.selectedDate = date;

        ApiCalendar.getCalendar(
          `?${this.$route.query.token}&date=${dateParms}&direction=future`
        )
          .then((res) => {
            if (
              res.data &&
              moment(dateParms).format("y-M") ==
                moment(Object.keys(res.data)[0]).format("y-M")
            ) {
              this.copyDataFromApi(res.data);
            } else if (
              moment(dateParms).format("y-M") !==
              moment(Object.keys(res.data)[0]).format("y-M")
            ) {
              console.log("no data");

              for (let el in this.monthChartData) {
                this.monthChartData[el] = [];
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    copyDataFromApi(data) {
      for (let el in this.monthChartData) {
        this.monthChartData[el].length = 0;

        if (el !== "date") {
          delete this.monthChartData[el];
        }
      }

      for (let el in this.filteredSelectedCharts) {
        if (this.filteredSelectedCharts[el].isShow == true) {
          this.monthChartData[this.filteredSelectedCharts[el].id] = [];
        }
      }

      for (let key in data) {
        if (
          moment(this.convertDateToString(this.selectedDate)).format("y-M") ==
          moment(key).format("y-M")
        ) {
          for (let el in this.monthChartData) {
            this.monthChartData[el].push(data[key][0][el]);
          }
        }
      }
    },
    filteredChartsDataArr() {
      this.selectedCharts.forEach((el) => {
        if (this.$route.query.gender == "male") {
          if (el.id !== "menstruation") {
            this.filteredSelectedCharts.push(el);
          }
        } else {
          this.filteredSelectedCharts.push(el);
        }
      });
    },
  },
  mounted() {
    this.filteredChartsDataArr();

    this.currentDate.year = new Date().getFullYear();
    this.currentDate.month = new Date().getMonth() + 1;

    this.selectedDate = this.currentDate;
  },
};
</script>

<style lang="scss" scoped>
.chart-page {
  padding-top: 20px;

  .tabs {
    margin-bottom: 10px;
  }

  .footer-btns {
    max-width: 309px;
    margin: auto;
    padding-top: 35px;
    padding-bottom: 80px;

    .button-white {
      margin-bottom: 31px;
    }
  }

  .scroll-area {
    margin-bottom: 33px;
  }

  .van-dialog {
    .tabs-title {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 16px;
      color: #897a6d;
      text-align: center;
    }
  }

  .checkboxs-list {
    .list-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.chart-page {
  .van-dialog {
    top: 50%;
    height: 100%;
    overflow: auto;
    max-height: 550px;

    @media (max-width: 330px) {
      max-height: 80%;
    }

    .tabs {
      margin-bottom: 40px;
      width: unset;
      display: flex;
      justify-content: center;
    }

    .van-dialog__content {
      width: 100%;
      max-width: 310px;
      position: relative;
      padding: 34px 24px 30px 24px;
    }

    .van-checkbox {
      .van-checkbox__label {
        margin-left: 9.4px;
        font-weight: 600;
        font-size: 20px;
        color: rgba(198, 179, 163, 1);
      }

      &.checked {
        .van-checkbox__label {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  .apexcharts-grid-borders {
    display: none;
  }

  .apexcharts-xaxis-tick {
    display: none;
  }
}
</style>
