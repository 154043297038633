export default {
	data() {
		return {
			selectedCharts: [
				{
					id: "mood",
					title: this.$t("mood.title"),
					height: 300,
					isShow: true,
					componentName: "MoodChart"
				},
				{
					id: "sleep_time",
					title: this.$t("sleep.title"),
					height: 410,
					isShow: false,
					componentName: "SleepChart"
				},
				{
					id: "anxiety",
					title: this.$t("mood.anxiety"),
					height: 300,
					isShow: false,
					componentName: "AnxietyChart"
				},
				{
					id: "aggression",
					title: this.$t("mood.aggression2"),
					height: 280,
					isShow: false,
					componentName: "SensitivityChart"
				},
				{
					id: "libido",
					title: this.$t("mood.libido"),
					height: 280,
					isShow: false,
					componentName: "SensitivityChart"
				},
				{
					id: "sensitivity",
					title: this.$t("mood.sensitivity2"),
					height: 280,
					isShow: false,
					componentName: "SensitivityChart"
				},
				{
					id: "appetite",
					title: this.$t("mood.appetite"),
					height: 280,
					isShow: false,
					componentName: "SensitivityChart"
				},
				{
					id: "pleasure",
					title: this.$t("history.text_8"),
					height: 280,
					isShow: false,
					componentName: "SensitivityChart"
				},
				{
					id: "sleep_quality",
					title: this.$t("mood.sleep_quality"),
					height: 300,
					isShow: false,
					componentName: "SleepQuality"
				},
				{
					id: "menstruation",
					title: this.$t("mood.menstruation"),
					height: 200,
					isShow: false,
					componentName: "MedsChart"
				},
				{
					id: "meds",
					title: this.$t("mood.meds"),
					height: 200,
					isShow: false,
					componentName: "MedsChart"
				},
				{
					id: "pav",
					title: this.$t("mood.pav"),
					height: 200,
					isShow: false,
					componentName: "MedsChart"
				},
				{
					id: "awake",
					title: this.$t("history.awakenings"),
					height: 200,
					isShow: false,
					componentName: "NightmaresChart"
				},
				{
					id: "nightmares",
					title: this.$t("history.nightmares"),
					height: 200,
					isShow: false,
					componentName: "NightmaresChart"
				},
				{
					id: "deep_sleep",
					title: this.$t("sleep.deep_dream"),
					height: 200,
					isShow: false,
					componentName: "DeepSleepChart"
				},
			],
		}
	}
}